.prodBanner{
    height:430px;
    width: 100%;
    background: linear-gradient(90deg, #001506 0.05%, rgba(0, 107, 56, 0.17) 99.94%), url(../images/products/prodBg.png);
    background-repeat: no-repeat;
    background-size: contain;
   /*  position: absolute; */
}
.pBannerTxt{
    position: relative;
    color:#ffff;
    top:100px;
    text-align: left;
}
.bannerPack{
    width:65%;
}
/* section 2 */
.white{
    height:488px;
    width: 100%;
    margin-bottom:-60px ;
}
.green{
    height:488px;
    width: 100%;
    background:#C6E7B2;

}
#green2{
    height:598px;
}
#greenOil{
    height: 350px;
}
.prodDetails{
    display: flex;
    margin-top: 40px;
}
.number{
    font-size: 9.875rem;
    letter-spacing: -2%;
    font-weight: 900;
    color:#69AB8C2B;
/*     line-height: 300px; */
    
}
.prodBtn{
    top: -250px;
    left:34%;
    position: relative;
}
#imgOnlyBtn{
    top: -285px;
    left:36.5%;
}
.type{
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-align: left;
    margin-top: 55px;
/*     line-height: 180px; */
}
.product p{
    
    font-size: 1.05rem;
    font-weight: 500;
     line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}
.prodCards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:2%;
    margin-top: 60px;
    margin-bottom: 70px;
}
.prodCard{
    border-radius: 2rem;
    padding: 9.5%;
    position: relative;
    top:-240px;
    background: rgba(255, 255, 255, 0.50);
    box-shadow: 6px 13px 41px 0px rgba(0, 0, 0, 0.14);
}
.snail{
    top:-255px;
}
.imgOnly{
    background:none;
    box-shadow: none;
    padding: 0%;
    margin-top: 40px;
    width: 100%;
}
.prodCardImg{
    /* border-radius: 25.557rem; */
/*     border: #001506 1px solid; */
    margin: auto 10%;
    width: 80%;
}
.oil{
    width:120% ;
    margin: 0;
    height: 405px;
}
#adjustSpice{
    height: 200px;
}
.prodCardTittle{
    margin-top: 30px;
}
.double{
    /*margin-top: -70px;*/
    margin-bottom: -140px;
}

@media screen and (min-width: 1600px) {
    .prodBanner{
        background-size: cover;
       /*  position: absolute; */
    }
    #green2{
        height:648px;
    }
    .double{
        margin-top:-40px ;
    }
    .prodBtn{
        top: -250px;
        left:36.5%;
    }
    #imgOnlyBtn{
        top: -270px;
        left:35.8%;
    }
    .s07, .oil{
        width:100% ;
    }
}
@media screen and (max-width: 1200px) {
    .prodBanner{
        background-size: cover;
       /*  position: absolute; */
    }
    .prodBtn{
        top: -270px;
        left:27%;
    }
    
}
@media screen and (max-width: 992px) {
    .pBannerTxt{
        top:80px;
       
    }
    #green2{
        height:698px;
    }
    .prodBtn{
        top: -270px;
        left: 25%;
    }
    #imgOnlyBtn{
        top: -280px;
        left:27.5%;
    }
    .section2{
        width: 93% !important;
        margin: auto auto !important;
    } 

}

@media screen and (max-width: 770px) {


    #adjustSpice{
        height: 140px;
    }
/*     #green2{
        height:698px;
    }
    .section2{
        width: 93% !important;
        margin: auto auto !important;
    }  */



}
@media screen and (max-width: 576px) {
    .bannerPack{
        width:100%;
    }
    .pBannerTxt{
        top:40px !important; 
        padding: 5%; 
    }
    .number{
        font-size: 1.875rem;
    }
    #imgOnlyBtn{
        top: -230px;
        left:0;
        
        
    }
    .white{
        height:568px;
        margin-bottom:-60px ;
    }
    .green{
        /* height:1068px; */
        height: auto;
        /* margin-bottom: 490px; */
    }
    #green2{
        /* height:1298px; */
        height: auto;
    }
    .prodBtn{
        top: -180px;
        left:0%;
    }
    #imgOnlyBtn{

    }
    #greenOil{
        height: 1150px;
        
    }
    .double{
        margin-top: 105px;
        margin-bottom:0px;
    }
    .prodCards{
        display: grid;
        grid-template-columns: 1fr;
        gap:2%;
        margin-top: 60px;
        margin-bottom: 70px;
    }
    .imgOnly{
        margin-top:-20px;
    }
    #prodImgOnly{
        grid-template-columns: 1fr;
        gap:0;
    }
    #prodImgOnly .prodCard{
        top:-180px;
    }
    .s06 #prodImgOnly{
        margin-top: 140px;
    }
    #prodImgOnly img{
        width: 100%;
        margin: 0;
    }
    .prodCardImg{
        /* border-radius: 25.557rem; */
    /*     border: #001506 1px solid; */
        margin: auto 10%;
        width: 80%;
    }
/*     .s04{
        margin-top: 600px;
    } */
/*     .s05,.s06,.s07,.s08,.s09{
        margin-top: -450px;
    }
    .s09{
        margin-bottom: -490px;
    } */
}