body::-webkit-scrollbar{
  scroll-behavior: smooth;
  display: none;
}
h1{
  font-size: 3.2rem;
  line-height:58px;
  letter-spacing: -0.05rem !important;
  font-weight: 900 !important;
}
h2{
  font-size: 2.8rem;
  font-weight: 700 !important;
}
.subHeading{
  font-size: 1.2rem;
  line-height:60px;
  letter-spacing: -0.055rem;
  font-weight: 200 !important;
  text-transform: uppercase;
}
.section{
  width:100%;
  /* width:78%; */
  margin:auto auto;
 
}
.section2{
  width:78% !important;
/*   width:72%; */
  margin:auto auto;
  /* border: solid 2px black; */
 /*  overflow-y: hidden */;
}
p.body{
  font-size:1.12rem;
  line-height: 24px;
}
p.lead{
  font-size: 1em;
  font-weight: 600;
  line-height: 28px;
}
p.cardTittle{
  color: #0F2A13;
  text-align: center;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: -0.036rem;
  font-size: 1.813rem;
}
.btnPrimary{
  border-radius: 0.25rem;
  background: #D78706;
  display: inline-flex;
  text-align: center;
  padding:1.188rem 2.938rem;
  color: #ffffff;
  border: none;
}
